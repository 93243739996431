<template>
  <span v-if="column.dataIndex === 'id'">
    {{ record.task?.id }}
  </span>

  <span v-if="column.dataIndex === 'taskName'">
    {{ record.task?.taskName }}
    <template v-if="hasCloneTask && isBillOfProcessUpdated">
      <a-divider class="mb-0" />
      <small class="text-danger d-inline-block">
        <span class="animated">Bill of Process Updated</span>
      </small>
      <small class="d-block">
        (Click
        <a-popconfirm
          cancel-text="No"
          ok-text="Yes"
          title="This will update all meta file. Do you want to proceed?"
          @confirm="handleUpdateBillOfProcess"
        >
          <template
            #icon
          ><QuestionCircleOutlined
            class="text-primary"
          /></template>
          <a class="text-primary">Here</a>
        </a-popconfirm>
        to update)
      </small>
    </template>
  </span>

  <span v-if="column.dataIndex === 'created_at'">
    {{ formatDate(record.task?.created_at) }}
  </span>

  <span v-if="column.dataIndex === 'numVideos'">
    {{ record.task?.videos.videos }}
  </span>

  <span v-if="column.dataIndex === 'numLabelled'">
    {{ record.task?.videos.labelled }}
  </span>

  <span v-if="column.dataIndex === 'Org_name'">
    {{ record.task?.Organization.Org_name }}
  </span>

  <span v-if="column.dataIndex === 'status_update_time'">
    {{ formatDate(record.status_update_time) }}
  </span>

  <span v-if="column.dataIndex === 'clonedFrom'">
    <template v-if="!record.task?.cloned_from"> - </template>
    <template v-else>
      {{ record.task.cloned_from.taskName }}
      <br>
      <span
        style="white-space: nowrap;"
      >({{ record.task.cloned_from.Organization }})</span>
    </template>
  </span>

  <span v-if="column.dataIndex === 'train_status'">
    <a-select
      v-model:value="selectedStatus"
      :options="statusOptions"
      placeholder="update status"
      class="mx-auto w-100"
      @change="handleStatusUpdate"
    />
  </span>
  <span v-if="column.dataIndex === 'action'">
    <a-dropdown :trigger="['click']" placement="bottomRight">
      <template #overlay>
        <a-menu id="task-record-menu-list">
          <a-menu-item v-if="!hasCloneTask" block @click="handleEditTaskClick">
            Edit Task
          </a-menu-item>
          <a-menu-item block @click="$emit('runImageSample', record)">
            Run Image Sample
          </a-menu-item>
          <a-menu-item @click="$emit('toggleShowModal', record.task.id)">
            Clone Task
          </a-menu-item>
          <a-menu-item
            v-if="hasCloneTask"
            :disabled="hasCloneTask && isBillOfProcessUpdated"
            @click="handleUpadateCloneTask('sync')"
          >
            Sync
          </a-menu-item>
          <a-menu-item
            v-if="hasCloneTask"
            @click="handleUpadateCloneTask('sync_videos')"
          >
            Sync Videos and Labels
          </a-menu-item>
          <a-menu-item
            v-if="hasCloneTask"
            @click="handleUpadateCloneTask('upload_labels')"
          >
            Update Labels in Copied Task
          </a-menu-item>
          <a-menu-item
            v-if="hasCloneTask"
            @click="handleUpadateCloneTask('upload_models')"
          >
            Update Models in Original Task
          </a-menu-item>
        </a-menu>
      </template>
      <a-button><template #icon> <MenuOutlined /> </template></a-button>
    </a-dropdown>
  </span>
</template>

<script>
import URL from 'src/services/baseUrl';
import httpClient from '../../../../service/httpClient';
import TaskService from '../../../../services/tasks';
import dateHelper from '../../../shared/Helpers/dateHelper';
import { MenuOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue';
import { getPayloadToUpdateCloneTaskBop } from '../../../../utils/task';
import cloneTaskActions from '../../../../config/clone-task-action-config';
import TaskStatusConfig from '@/config/training-status-config.js';

export default {
  components: { MenuOutlined, QuestionCircleOutlined },
  inject: ['toast'],
  props: ['item', 'column', 'statusOptions'],
  emits: [
    'toggleShowModal',
    'updateList',
    'runImageSample',
    'setCloneTaskUpdating',
    'setEditTask',
  ],
  setup() {
    return { formatDate: dateHelper.formatDate };
  },
  data() {
    return {
      selectedStatus: '',
      record: {},
      isBillOfProcessUpdated: false,
      parent_task: null,
    };
  },
  computed: {
    hasCloneTask() {
      return this.record.task?.cloned_from;
    },
    originalTaskDetails() {
      if (!this.record.task?.cloned_from) return '-';
      const { taskName, Organization } = this.record.task.cloned_from;
      return `${taskName}(${Organization})`;
    },
  },
  watch: {
    item(data) {
      this.record = data;
    },
    record(data) {
      this.selectedStatus = data?.train_status;
      this.parent_task = data?.task.cloned_from;
      if (data.task?.cloned_from)
        this.isBillOfProcessUpdated = this.isTaskDetailsSame(data);
    },
  },
  mounted() {
    this.record = this.item;
  },
  methods: {
    isTaskDetailsSame(data) {
      let clone_task_detail = data.task.task_detail;
      let original_task_detail = data.task.cloned_from.task_detail;
      clone_task_detail = clone_task_detail
        .split('\n')
        .slice(1)
        .join('\n');
      original_task_detail = original_task_detail
        .split('\n')
        .slice(1)
        .join('\n');
      return clone_task_detail !== original_task_detail;
    },

    handleUpdateList(data) {
      this.$emit('updateList', {
        id: this.record.id,
        updatedData: data,
      });
    },

    async handleUpdateBillOfProcess() {
      this.$emit('setCloneTaskUpdating', true);
      const {
        record: { task },
        parent_task,
      } = this;
      const taskPayload = getPayloadToUpdateCloneTaskBop(task, parent_task);
      console.log('taskPayload ->', taskPayload);
      const [error, _] = await TaskService.updateTask(
        task.id,
        taskPayload,
        false
      );
      this.$emit('setCloneTaskUpdating', false);
      if (error) {
        this.toast.error('Error occured in updating Bill Of Process');
        return;
      }
      this.toast.success('Bill of Process updated');
      this.handleUpdateList({
        task: {
          ...this.record.task,
          task_detail: this.parent_task.task_detail,
        },
      });
    },

    async handleStatusUpdate(value) {
      const payload = {
        train_status: value,
      };
      if (value === TaskStatusConfig.trained)
        payload['training_complete_time'] = new Date().toISOString();

      const [error, _] = await TaskService.updateTrainedTask(
        this.record.task.id,
        payload,
        false
      );
      if (error) {
        this.toast.error('Error occured in updating Task Status');
        return;
      }

      this.handleUpdateList(payload);
    },

    async handleUpadateCloneTask(type) {
      this.$emit('setCloneTaskUpdating', true);
      const payload = {
        task_id: this.record.task.id,
        action: cloneTaskActions[type],
      };
      const res = await httpClient.patch(
        'generic/clone_task/',
        '',
        payload,
        false,
        false,
        false,
        false,
        false,
        URL.testHostUrl
      );
      this.$emit('setCloneTaskUpdating', false);
      if (res === 'error') {
        this.toast.error('Error occured in updating Cloned Task');
        return;
      }
      this.toast.success('Task updated');
    },
    handleEditTaskClick() {
      this.$emit('setEditTask', this.record);
    },
  },
};
</script>

<style>
.animated {
  animation: blinker 1.4s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
</style>
